import React, { useState, useEffect } from "react"

import vile from "../../images/vile.svg"
import doc from "../../images/document.svg"
import femur from "../../images/femur.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const KitTabs = ({ children }) => {
  const [activeE, setActiveE] = useState("kit")
  const [initial, setInitial] = useState(false)

  const toggleCurrentActive = e => {
    let tc = e.currentTarget.dataset.id
    if (tc.length) {
      setActiveE(tc)
    } else {
      setActiveE("")
    }
    if (!initial) {
      setInitial(true)
    }
    e.preventDefault()
  }

  const getActiveE = name => {
    let cn = name
    if (cn === activeE) {
      return true
    } else {
      return false
    }
  }

  // const checkActiveClass = name => {
  //   let cn = name
  //   if (cn === activeE) {
  //     return ""
  //   } else {
  //     return "isMuted"
  //   }
  // }

  useEffect(() => {
    if (initial) {
      if (activeE.length) {
        let elements = document.getElementsByClassName(activeE)
        let ot = ""
        let iw = typeof window !== "undefined" ? window.innerWidth : ""
        if (iw > 1024) {
          window.scrollTo({
            top: elements[0].offsetTop + 20,
            behavior: "smooth",
          })
        } else {
          window.scrollTo({
            top: elements[0].offsetTop + 150,
            behavior: "smooth",
          })
        }
      }
    } else {
      return false
    }
  }, [activeE])

  return (
    <div className="kit-tabs lg_mt-1  kit-tab-diagnosing">
      <div className="row kit-tabs__tab-deck small-collapse medium-uncollapse">
        <div className="columns small-12 medium-expand stretch mt-1">
          <button
            data-id="kit"
            onClick={e => toggleCurrentActive(e)}
            className={getActiveE("kit") ? `isActive` : ``}
          >
            KIT D816V
            <br /> peripheral blood
          </button>
        </div>
        <div className="columns small-12 medium-expand stretch">
          <button
            data-id="serum"
            onClick={e => toggleCurrentActive(e)}
            className={getActiveE("serum") ? `isActive` : ``}
          >
            Serum
            <br /> tryptase
          </button>
        </div>

        <div className="columns small-12 medium-expand stretch mt-1">
          <button
            data-id="biopsy"
            onClick={e => toggleCurrentActive(e)}
            className={getActiveE("biopsy") ? `isActive` : ``}
          >
            Bone marrow
            <br /> biopsy
          </button>
        </div>
      </div>
      <div className="row kit-tabs__icon-deck lg_mt-4 mt-2">
        <div className="columns small-12 medium-expand">
          {getActiveE("kit") && (
            <div className="icon">
              <img src={vile} alt="Blood vial" />
            </div>
          )}
        </div>
        <div className="columns small-12 medium-expand">
          {getActiveE("serum") && (
            <div className="icon">
              <img src={doc} alt="Serum tryptase document" />
            </div>
          )}
        </div>

        <div className="columns small-12 medium-expand">
          {getActiveE("biopsy") && (
            <div className="icon">
              <img src={femur} alt="Bone marrow biopsy" />
            </div>
          )}
        </div>
      </div>
      <div className="row kit-tabs__tabs-deck">
        {getActiveE("kit") && (
          <>
            {/* tabby */}
            <div className="columns kit">
              <div className="purple-card">
                <br />
                <p className="bodyP mb-0 lg_mt-4 mt-3 strong text-center">
                  KIT D816V testing, used in peripheral blood, can be a first
                  step to evaluate SM
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  The KIT D816V mutation is the main driver of disease in ~95%
                  of SM cases.<sup>5-8</sup>
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  Low-sensitivity assays may fail to detect the KIT D816V
                  mutation and potentially prolong diagnosis.<sup>9,10</sup> It
                  is recommended to use a high-sensitivity test for detection of
                  KIT D816V.<sup>9,10</sup> Quantitative digital PCR testing is
                  commercially available with limits of detection ≤0.03%.
                  <sup>9,10</sup>
                </p>

                <a
                  href="/kit-testing-commercial-lab-sheet.pdf"
                  target="_blank"
                  className="tab_link no-ext"
                >
                  Find a lab offering high-sensitivity KIT D816V assays{" "}
                  <FontAwesomeIcon className="" icon={faAngleRight} />
                </a>
                <p className="bodyP lg_mt-2 mt-2">
                  Detection of a KIT D816V mutation is a minor criterion for SM
                  diagnosis.<sup>4</sup> Further workup may be required to
                  assess if a patient meets the diagnostic criteria for SM
                  diagnosis.
                  <sup>4</sup>
                </p>
                <a
                  href="/diagnosing-sm/#diagnostic-criteria"
                  className="tab_link"
                >
                  See SM diagnostic criteria
                  <FontAwesomeIcon className="" icon={faAngleRight} />
                </a>
              </div>
            </div>
            {/* tabby */}
          </>
        )}

        {getActiveE("serum") && (
          <>
            {/* tabby */}
            <div className="columns serum">
              <div className="purple-card">
                <br />
                <p className="bodyP mb-0 lg_mt-4 mt-3 strong text-center">
                  Along with KIT D816V, measure serum tryptase
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  Elevated serum total tryptase (&gt;20 ng/mL) may qualify as a
                  minor criterion for SM diagnosis.<sup>1</sup> However, 30% of
                  patients with ISM exhibit a serum tryptase level lower than
                  (20 ng/mL).
                  <sup>4</sup> A serum tryptase value in the normal range does
                  not reliably exclude SM.<sup>11</sup>
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  The serum tryptase test is one of several tests that can be
                  used to confirm suspicion of SM.<sup>4</sup> Further workup
                  may be required to assess if a patient meets the diagnostic
                  criteria for SM diagnosis.<sup>4</sup>
                </p>
                <a
                  href="/diagnosing-sm/#diagnostic-criteria"
                  className="tab_link"
                >
                  See SM diagnostic criteria
                  <FontAwesomeIcon className="" icon={faAngleRight} />
                </a>
                {/* <div className="row lg_mt-3 mt-2">
                  <div className="columns">
                    <ul className="hanging-ref color--white">
                      <li>
                        <span className="ref">*</span>Unless there is an
                        associated myeloid neoplasm, in which case this
                        parameter is not valid.<sup>3</sup>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            {/* tabby */}
          </>
        )}

        {getActiveE("biopsy") && (
          <>
            {/* tabby */}
            <div className="columns biopsy">
              <div className="purple-card">
                <br />
                <p className="bodyP mb-0 lg_mt-4 mt-3 strong text-center">
                  Bone marrow involvement is nearly always involved in SM and
                  may be required for diagnosis
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  A bone marrow examination is recommended to distinguish
                  between adult-onset mastocytosis in the skin and SM when
                  typical skin lesions are observed.<sup>4,12</sup>
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  Multifocal dense infiltrates of mast cells in bone marrow* are
                  the major criterion for SM diagnosis.<sup>4</sup> Atypical and
                  neoplastic mast cells in bone marrow* may be used as a minor
                  criterion for SM diagnosis.<sup>4</sup>
                </p>
                <p className="bodyP lg_mt-2 mt-2">
                  A bone marrow examination is one of several tests that can be
                  used to confirm suspicion of SM.<sup>4</sup> Further workup
                  may be required to assess if a patient meets the diagnostic
                  criteria for SM diagnosis.<sup>4</sup>
                </p>
                <a
                  href="/diagnosing-sm/#diagnostic-criteria"
                  className="tab_link"
                >
                  See SM diagnostic criteria
                  <FontAwesomeIcon className="" icon={faAngleRight} />
                </a>

                <div className="row lg_mt-1 mt-1">
                  <div className="columns">
                    <ul className="hanging-ref color--white">
                      <li>
                        <span className="ref">*</span>And/or other
                        extracutaneous organ(s).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* tabby */}
          </>
        )}
      </div>
    </div>
  )
}

export default KitTabs
