import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReferenceBlock from "../components/template-partials/reference-block"
import FooterCrown from "../components/template-partials/footer-crown"
import KitTabs from "../components/template-partials/kit-tabs"
import downloadIcon from "../images/download_icon.svg"
import QuestionsSM from "../components/template-partials/questions-sm"
import linkOutIcon from "../images/link_out_bold.svg"
import hrImage from "../images/hr-image.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import ScreenTools from "../images/screen-tools.png"

const QuestionsSMBlockButtons = [
  {
    text: "See Peer Directory",
    to: "https://www.smdirectory.com/",
    icon: linkOutIcon,
    newTab: true,
    modalLinkBp: true,
  },
]

const DiagnosingSMPage = () => (
  <Layout route="diagnosing-sm">
    <Seo
      page="diagnosing-sm"
      title="Diagnosing Systemic Mastocytosis (SM)"
      description="Evaluate what is included in a workup to confirm a systemic mastocytosis (SM) diagnosis, including testing for a KIT D816V mutation."
    />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Screen and test for systemic mastocytosis (SM) at the
          <span className="powder"> first signs of the disease</span>
          <sup>1,2</sup>
        </h1>
        <p className="h2 bold mt-3 purple">
          A thorough workup should be initiated once SM is suspected based on{" "}
          <a href="/recognizing-sm/symptoms/" className="powder underline">
            hallmark symptoms
          </a>{" "}
          <span className="powder">(skin lesions, anaphylaxis, diarrhea)</span>{" "}
          or other symptoms of mast cell activation<sup>1,3,4</sup>
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 lg_mb-2 align-center medium-align-left">
      <div className="columns small-12 ">
        <div className="screen-tools ">
          <img
            src={ScreenTools}
            alt=""
            width="100%"
            height="auto"
            className="screenToolsImg"
          />
          <div className="hr-image-vertical">
            <div className="line"></div>
            <img src={hrImage} alt="" className="hrImageIcon" />
            <div className="line"></div>
          </div>
          <div className="text-box">
            <p className="text-box-header text--watch-quinn purple">
              Did you know?
            </p>
            <p className="purple bodyP">
              Screening tools can help lead to an SM diagnosis.
              <sup>4</sup>
            </p>
            <p className="purple bodyP">
              Testing serum tryptase levels and KIT D816V mutation in peripheral
              blood are 2 components to screen for SM.
              <sup>4</sup>
            </p>
            <p className="purple bodyP">
              A bone marrow biopsy may be required for diagnosis.
              <sup>4</sup>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <KitTabs />
    </div>

    <div className="row lg_mt-4 mt-4  align-center medium-align-left">
      <div className="columns small-12 text-center">
        <p className="color--purple lead font22">
          <strong>Resources for hematopathologists</strong>
        </p>
      </div>
    </div>

    <div className="row lg_mb-2 mb-2 align-center medium-align-left">
      <div className="columns small-12 text-center">
        <div className="two-col-buttons">
          <div className="two-col-buttons-inner">
            <p>
              A guide to the integral role a hematopathologist plays in the
              accurate diagnosis of SM
            </p>

            <a
              className="mustard-btn no-ext mustard_hover"
              href="/PME-SM-Brochure-Role-of-the-Hematopathologist-(USBP-DASM-22-011).pdf"
              target="_blank"
            >
              <span>
                Download role of
                <br className="show-for-large" /> the hematopathologist
                <br className="show-for-large" /> brochure
              </span>{" "}
              <img alt="" src={downloadIcon} width="100%" height="auto" />
            </a>
          </div>

          <div className="two-col-buttons-inner">
            <p>
              A tool to record patients’ diagnostic lab results in the
              assessment of an SM diagnosis
            </p>

            <a
              className="mustard-btn no-ext mustard_hover"
              href="/SM-Diagnostic-Criteria-Reference-Sheet_USDSA23.0003.pdf"
              target="_blank"
            >
              <span>
                Download THE sm diagnostic
                <br className="show-for-large" /> criteria reference sheet
              </span>{" "}
              <img alt="" src={downloadIcon} width="100%" height="auto" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />
    <div id="diagnostic-criteria"></div>
    <div className="row lg_mt-1 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <p className="h2 bold mt-3 purple">
          A diagnosis of SM can be made using criteria established by the{" "}
          <span className="powder">World Health Organization (WHO)</span> and/or
          the{" "}
          <span className="powder">
            International Consensus Classification (ICC)
          </span>
          <sup>4,9</sup>*
        </p>
        {/* <p className="bodyP purple">
          In 2022, 2 independent updates to the previous 2016 WHO classification
          and diagnostic criteria (revised 4th edition) for mastocytosis were
          published, namely the ICC of Myeloid Neoplasms and Acute Leukemias and
          the 5th edition of the WHO Classification of Haematolymphoid Tumours.
          <sup>4,9</sup>
        </p> */}

        <div className="text-flex-div border-bottom">
          <p className="textleft">Major criterion</p>
          <div>
            <p>
              Multifocal dense infiltrates of tryptase and/or CD117-positive
              mast cells<sup>†‡</sup>
            </p>
          </div>
        </div>

        <div className="text-flex-div">
          <p className="textleft">Minor criteria</p>
          <div>
            <ul>
              <li>
                &gt;25% of mast cells are spindle-shaped or have an atypical or
                immature morphology<sup>‡</sup>
              </li>
              <li>
                KIT D816V mutation or other activating KIT mutation<sup>‡</sup>
              </li>
              <li>
                Elevated serum tryptase level, &gt;20 ng/mL<sup>§</sup>
              </li>
              <li>
                Mast cells<sup>‡</sup> express CD25, CD2, and/or CD30
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-0 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-7 centered_div">
        <div className="percent_col one_col ">
          <div className="col_inner ">
            <p className="font22 purple">
              <strong>
                It is important to explore the minor diagnostic criteria, as it
                is possible that up to
              </strong>
            </p>
            <p className="percent_header mt-2 mb-1">
              <span className="lg-font text--watch-quinn">~43%</span> of SM
              cases
            </p>
            <p className="font22 purple">
              <strong>
                may not fulfill the major criteria.<sup>4,8</sup>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns medium-expand">
        <br />
        <ul className="hanging-ref color--purple">
          <li>
            <span className="ref">*</span>
            This highlights the combined key criteria based on the ICC and
            proposed changes to the WHO 5th edition guidelines and is not
            intended as diagnostic advice. Full guidelines are available from
            the WHO and ICC.
          </li>
          <li>
            <sup className="ref">†</sup>
            ≥15 mast cells in aggregates.
          </li>
          <li>
            <sup className="ref">‡</sup>
            In bone marrow biopsies and/or in sections of other extracutaneous
            organ(s).
          </li>
          <li>
            <sup className="ref">§</sup>
            This parameter is not valid in the presence of a myeloid AHN and may
            need to be adjusted in the case of known HɑT.
          </li>
          <li>
            AHN=associated hematological neoplasm; CD=cluster of
            differentiation; HαT=hereditary alpha-tryptasemia; KIT=KIT
            proto-oncogene, receptor tyrosine kinase; PCR=polymerase chain
            reaction.
          </li>
        </ul>
      </div>
    </div>
    <br />
    <QuestionsSM
      hrImage={hrImage}
      text="SM Provider Peer Directory"
      body_text="An online resource that lists the contact information of volunteer healthcare providers who have attested that they have experience managing patients living with SM and are willing to independently connect with peers who have questions about their medical experience."
      QuestionsSMBlockButtons={QuestionsSMBlockButtons}
    />

    <div className="row lg_mt-10 mt-10 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple">
          See how patients with SM might present in your practice
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/sm-profiles-case-studies/"
        >
          EXPLORE HYPOTHETICAL CASES{" "}
          <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />

    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
        <li>
          Shomali W, Gotlib J. <em>Hematology</em>. 2018;2018(1):127-136.
        </li>
        <li>
          WHO Classification of Tumours Editorial Board. Haematolymphoid tumours
          [Internet]. Lyon (France): International Agency for Research on
          Cancer; 2024 [cited April 24, 2024]. (
          <em>WHO Classification of Tumours Series</em>, 5th ed.; vol. 11).
          Available from: https://tumourclassification.iarc.who.int/chapters/63
        </li>
        <li>
          Garcia-Montero AC et al. <em>Blood</em>. 2006;108(7):2366-2372.
        </li>
        <li>
          Evans EK et al. <em>Sci Transl Med</em>. 2017;9(414):eaao1690.
        </li>
        <li>
          Kristensen T et al. <em>Am J Hematol</em>. 2014;89(5):493-498.
        </li>
        <li>
          Ungerstedt J et al. <em>Cancers</em>. 2022;14(16):3942.
        </li>
        <li>
          Arber DA et al. <em>Blood</em>. 2022;140(11):1200-1228.
        </li>
        <li>
          Jara-Acevedo M et al. <em>Mod Pathol</em>. 2015;28(8):1138-1149.
        </li>
        <li>
          Akin C et al. <em>Immunol Allergy Clin North Am</em>.
          2014;34(2):207-218.
        </li>
        <li>
          Hartmann K et al. <em>J Allergy Clin Immunol</em>. 2016;137(1):35-45.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default DiagnosingSMPage
