// src/components/ButtonGroup.js
import React from "react"
import { Link } from "gatsby"
// import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const QuestionsSMBlock = ({
  QuestionsSMBlockButtons,
  text,
  hrImage,
  body_text,
}) => {
  return (
    <div className="row lg_mt-3 mt-2 small-collapse medium-uncollapse mobile-padding">
      <div className="questionsSM-Block ">
        <div className="hr-image">
          <div className="line"></div>
          <img src={hrImage} alt="" />
          <div className="line"></div>
        </div>
        <p className="text--watch-quinn">{text}</p>
        <p className="bodyP">{body_text}</p>
        <div className="button-group ">
          {QuestionsSMBlockButtons.map((button, index) => (
            <a
              target={button.newTab ? "_blank" : "_self"}
              href={button.to}
              key={index}
              className={`button ${
                button.modalLinkBp
                  ? "modal-link-bp mustard_hover"
                  : "mustard_hover"
              }`}
            >
              <span>{button.text}</span>
              {button.icon && (
                <img src={button.icon} alt="" className="button-icon" />
              )}

              {button.arrow && (
                <FontAwesomeIcon className="" icon={faAngleRight} />
              )}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuestionsSMBlock
